import { Routes, Route } from "react-router-dom";

import Protected from "./Protected";
import ProtectedCustomer from "./ProtectedCustomer";

import DashboardPage from "../pages/dashboard/DashboardPage";

import RegistrationPage from "../pages/registration/RegistrationPage";
import LoginPage from "../pages/login/userLogin/LoginPage";
import ResetPasswordPage from "../pages/reset-password/ResetPasswordPage";

import GalleriesPage from "../pages/galleries/galleries-page/GalleriesPage";
import NewGalleryPage from "../pages/galleries/new-gallery/NewGalleryPage";
import GalleryPage from "../pages/galleries/gallery-page/GalleryPage";
import GalleryResume from "../pages/galleries/gallery-resume/GalleryResume";
import GallerySettingsPage from "../pages/galleries/gallery-settings/GallerySettingsPage";

import CustomersPage from "../pages/customers/customers-page/CustomersPage";
import CustomerPage from "../pages/customers/customer-page/CustomerPage";
import NewCustomerPage from "../pages/customers/new-customer/NewCustomerPage";
import CustomerGalleriesPage from "../pages/customers/customer-galleries/CustomerGalleriesPage";

import PrivacyPolicyPage from "../pages//privacyPolicy/PrivacyPolicyPage";
import ContactPage from "../pages/contact/ContactPage";
import SettingsPage from "../pages/settings/SettingsPage";

import CustomerPanelPage from "../pages/customer-panel/CustomerPanelPage";
import CustomerGalleryPage from "../pages/customer-panel/customer-gallery-page/CustomerGalleryPage";
import CustomerLogin from "../pages/login/customerLogin/CustomerLogin";

import BundlesPage from "../pages/bundles/bundles-page/BundlesPage";
import LandingPage from "../pages/landing/LandingPage";

const Router = (props) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="registration" element={<RegistrationPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route
        path="dashboard"
        element={
          <Protected token={token} role={role}>
            <DashboardPage />
          </Protected>
        }
      />
      <Route
        path="galleries"
        element={
          <Protected token={token} role={role}>
            <GalleriesPage />
          </Protected>
        }
      />
      <Route
        path="galleries/new-gallery"
        element={
          <Protected token={token} role={role}>
            <NewGalleryPage />
          </Protected>
        }
      />
      <Route
        path="galleries/:id"
        element={
          <Protected token={token} role={role}>
            <GalleryPage />
          </Protected>
        }
      />
      <Route
        path="galleries/resume/:id"
        element={
          <Protected token={token} role={role}>
            <GalleryResume />
          </Protected>
        }
      />
      <Route
        path="galleries/settings/:id"
        element={
          <Protected token={token} role={role}>
            <GallerySettingsPage />
          </Protected>
        }
      />
      <Route
        path="customers"
        element={
          <Protected token={token} role={role}>
            <CustomersPage />
          </Protected>
        }
      />
      <Route
        path="customers/:id"
        element={
          <Protected token={token} role={role}>
            <CustomerPage />
          </Protected>
        }
      />
      <Route
        path="customers/new-customer"
        element={
          <Protected token={token} role={role}>
            <NewCustomerPage />
          </Protected>
        }
      />
      <Route
        path="customers/:id/galleries"
        element={
          <Protected token={token} role={role}>
            <CustomerGalleriesPage />
          </Protected>
        }
      />

      <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
      <Route
        path="contact"
        element={
          <Protected token={token} role={role}>
            <ContactPage />
          </Protected>
        }
      />
      <Route
        path="settings"
        element={
          <Protected token={token} role={role}>
            <SettingsPage />
          </Protected>
        }
      />
      <Route
        path="bundles"
        element={
          <Protected token={token} role={role}>
            <BundlesPage />
          </Protected>
        }
      />
      <Route
        path="customer-panel"
        element={
          <ProtectedCustomer token={token} role={role}>
            <CustomerPanelPage />
          </ProtectedCustomer>
        }
      />
      <Route
        path="customer-panel/gallery/:id"
        element={
          <ProtectedCustomer token={token} role={role}>
            <CustomerGalleryPage />
          </ProtectedCustomer>
        }
      />
      <Route path="customer-login" element={<CustomerLogin />} />
    </Routes>
  );
};

export default Router;
