import styled from "styled-components";

const ConfirmationBoxWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  width: 100vw;
  height: 100vh;

  .question-modal {
    width: 90%;
    max-width: 400px;
    background: #fff;
    padding: 40px;
  }

  button {
    margin: 10px 0;
  }
`;

const ConfirmationBox = (props) => {
  return (
    <>
      <ConfirmationBoxWrapper onClick={props.negativeAction}>
        <div className="question-modal">
          <p>{props.question}</p>
          <div className="buttons-wrapper">
            <button onClick={props.positiveAction}>Tak</button>
            <button onClick={props.negativeAction}>Nie</button>
          </div>
        </div>
      </ConfirmationBoxWrapper>
    </>
  );
};

export default ConfirmationBox;
