import { useState } from "react";
import styles from "./formInput.module.scss";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, placeholder, errorMessage, onChange, id, isRequired, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className={styles.inputContainer}>
      <label>
        {label} {isRequired ? <p className={styles.dot}>*</p> : ""}
      </label>
      <input
        {...inputProps}
        onChange={onChange}
        onBlur={handleFocus}
        placeholder={placeholder}
        onFocus={() => inputProps.name === "confirmPassword" && setFocused(true)}
        focused={focused.toString()}
        style={{ background: `${props.background}` }}
      />
      <span>{errorMessage}</span>
    </div>
  );
};

export default FormInput;
