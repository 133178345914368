import styles from "./Alert.module.css";
import CloseButton from "../../../static/svg/CloseButton";

const Alert = (props) => {
  return (
    <div className={styles.alert}>
      <div>
        <div onClick={props.close} className={styles.closeButton}>
          <CloseButton color="#151414" width="20" height="20" />
        </div>
      </div>
      <div>
        <p className={styles.message}>{props.message}</p>
      </div>
    </div>
  );
};

export default Alert;
