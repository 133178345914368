import { useState, useEffect } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import styles from "./gallerySettings.module.scss";
import axios from "axios";

import Page from "../../../components/layout/page/Page";
import FormSelect from "../../../components/layout/Form/form-select/FormSelect";
import FormInput from "../../../components/layout/Form/form-input/FormInput";
import ArrowBack from "../../../static/svg/ArrowBack";
import LoadingBar from "../../../components/layout/LoadingBar";
import Alert from "../../../components/layout/Alert/Alert";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";
import UserIcon from "../../../static/svg/UserIcon";
import PrimaryButton from "../../../components/layout/primaryButton/PrimaryButton";

const GallerySettingsPage = () => {
  const [gallery, setGallery] = useState({
    title: "",
    customer: "",
    date: "",
    type: "",
    price: 0,
    paid: 0,
    shotsQt: 0,
    additionalShotPrice: 0,
    ifPrintings: false,
    additionalPrintings: [],
  });
  const [price1015, setPrice1015] = useState(0);
  const [price1521, setPrice1521] = useState(0);
  const [price2030, setPrice2030] = useState(0);
  const [price3040, setPrice3040] = useState(0);
  const [freePrintings1015, setFreePrintings1015] = useState(0);
  const [freePrintings1521, setFreePrintings1521] = useState(0);
  const [freePrintings2030, setFreePrintings2030] = useState(0);
  const [freePrintings3040, setFreePrintings3040] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let params = useParams();
  const navigate = useNavigate();

  const SESSION_TYPES = [
    {
      _id: "Sesja rodzinna",
      name: "Sesja rodzinna",
    },
    {
      _id: "Sesja dziecięca",
      name: "Sesja dziecięca",
    },
    {
      _id: "Sesja noworodkowa",
      name: "Sesja noworodkowa",
    },
    {
      _id: "Sesja ciążowa",
      name: "Sesja ciążowa",
    },
    {
      _id: "Reportaż",
      name: "Reportaż",
    },
    {
      _id: "Inne",
      name: "Inne",
    },
  ];

  const PRINTINGS_PRICE = [
    {
      size: "10 x 15",
      value: price1015,
      setValue: setPrice1015,
      freePrintings: freePrintings1015,
      setFreePrintings: setFreePrintings1015,
    },
    {
      size: "15 x 21",
      value: price1521,
      setValue: setPrice1521,
      freePrintings: freePrintings1521,
      setFreePrintings: setFreePrintings1521,
    },
    {
      size: "20 x 30",
      value: price2030,
      setValue: setPrice2030,
      freePrintings: freePrintings2030,
      setFreePrintings: setFreePrintings2030,
    },
    {
      size: "30 x 40",
      value: price3040,
      setValue: setPrice3040,
      freePrintings: freePrintings3040,
      setFreePrintings: setFreePrintings3040,
    },
  ];

  useEffect(() => {
    getGallery();
    getCustomers();
    getCustomer();
  }, [customerID]);

  const getCustomers = async () => {
    await axios.get("/customers").then((res) => {
      setCustomers(res.data);
    });
  };

  const getCustomer = async () => {
    await axios.get(`/customers/${customerID}`).then((res) => {
      setCustomer(res.data);
    });
  };

  const getGallery = async () => {
    setLoading(true);
    await axios.get(`/galleries/${params.id}`).then((res) => {
      setPrice1015(res.data.additionalPrintings[0].value);
      setPrice1521(res.data.additionalPrintings[1].value);
      setPrice2030(res.data.additionalPrintings[2].value);
      setPrice3040(res.data.additionalPrintings[3].value);
      setGallery(res.data);
      setCustomerID(res.data.customer);
    });
    setLoading(false);
  };

  const editGallery = async (e) => {
    e.preventDefault();
    if (gallery.status !== "new") {
      setMessage("Galeria została wysłana, nie ma możliwości edycji");
    } else {
      setLoading(true);

      let updatedGallery = gallery;
      updatedGallery.additionalPrintings[0].value = price1015 ? price1015 : 0;
      updatedGallery.additionalPrintings[1].value = price1521 ? price1521 : 0;
      updatedGallery.additionalPrintings[2].value = price2030 ? price2030 : 0;
      updatedGallery.additionalPrintings[3].value = price3040 ? price3040 : 0;

      // Dla kazdeog zdjecia nalezy zmapowowac wydruki i ponownie nadac im cene.

      let photos = updatedGallery.photos.map((photo, index) => {
        return photo;
      });

      photos.forEach((photo) => {
        photo.printings[0].price = price1015 ? price1015 : 0;
        photo.printings[1].price = price1521 ? price1521 : 0;
        photo.printings[2].price = price2030 ? price2030 : 0;
        photo.printings[3].price = price3040 ? price3040 : 0;
      });

      updatedGallery.photos = photos;

      await axios
        .put(`/galleries/${params.id}`, updatedGallery)
        .then((res) => {
          setMessage("Poprawnie zedytowano galerię");
        })
        .catch((error) => {
          setMessage(error.response.data.message);
        });

      setLoading(false);
    }
  };

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.returnButton}>
          <ReturnButton title="Powrót do galerii" url={`/galleries/${params.id}`} />
        </div>
        <div className={styles.header}>
          <h3>Ustawienia galerii</h3>
          <p>Zmiany mogą być dokonywane tylko przed wysłaniem jej do klienta.</p>
        </div>
        <form className={styles.editForm} onSubmit={editGallery}>
          <div className={styles.data}>
            <div className={styles.primaryData}>
              <div className={styles.selectClient}>
                <FormSelect
                  onChange={(e) =>
                    setGallery((prevState) => {
                      return { ...prevState, customer: e.target.value };
                    })
                  }
                  name="customer"
                  id="customer"
                  label="Wybierz klienta z listy"
                  firstgroup="Wybrany klient"
                  secondgroup="Lista klientów"
                  selection={customer.name}
                  initialOption={customer.name}
                  values={customers}
                  pattern=".{1,}"
                  required
                  icon={<UserIcon color="#191919" />}
                />

                <NavLink to="/customers/new-customer" state={{ from: "galleries/new-gallery" }}>
                  <div className={styles.addClientButton}>
                    <UserIcon color="#0099F0" />
                    <p>Dodaj klienta</p>
                  </div>
                </NavLink>
              </div>
              <div className={styles.selectTitle}>
                <FormInput
                  onChange={(e) =>
                    setGallery((prevState) => {
                      return { ...prevState, title: e.target.value };
                    })
                  }
                  value={gallery.title}
                  type="text"
                  label="Nazwa galerii"
                  placeholder="Podaj nazwę galerii"
                  name="title"
                  pattern=".{1,50}"
                  errorMessage="Nazwa galerii jest wymagana i może zawierać maksymalnie 50 znaków."
                  required
                  isRequired={true}
                />
              </div>

              <div className={styles.selectDateAndType}>
                <div className={styles.dateInput}>
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, date: e.target.value };
                      })
                    }
                    value={gallery.date}
                    label="Data wydarzenia"
                    name="date"
                    type="date"
                    errorMessage="Data wydarzenia jest wymagana."
                    required
                    isRequired={true}
                  />
                </div>
                <div className={styles.typeSelect}>
                  <FormSelect
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, type: e.target.value };
                      })
                    }
                    name="type"
                    id="type"
                    label="Rodzaj sesji"
                    values={SESSION_TYPES}
                    initialOption={gallery.type}
                    selection={gallery.type}
                    pattern=".{1,}"
                    errorMessage="Rodzaj sesji jest wymagany."
                    required
                  />
                </div>
              </div>

              <div className={styles.selectPrice}>
                <div className={styles.priceSelect}>
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, price: e.target.value };
                      })
                    }
                    value={gallery.price || 0}
                    label="Cena sesji (zł)"
                    name="price"
                    min="0"
                    type="number"
                    errorMessage="Cena sesji jest wymagana a wartość nie może być mniejsza niż 0."
                    required
                  />
                </div>
                <div className={styles.paidSelect}>
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, paid: e.target.value };
                      })
                    }
                    value={gallery.paid || 0}
                    label="Zapłacono (zł)"
                    name="paid"
                    id="paid"
                    min="0"
                    max={gallery.price}
                    type="number"
                    errorMessage="Wartość nie może być większa od ceny sesji i mniejsza niż 0."
                    required
                  />
                </div>
              </div>

              <div className={styles.selectShots}>
                <div className={styles.shotsSelect}>
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, shotsQt: e.target.value };
                      })
                    }
                    value={gallery.shotsQt}
                    label="Ilość ujęć w cenie sesji"
                    name="shots"
                    id="shots"
                    min="0"
                    type="number"
                    errorMessage="Ilość ujęć jest wymagana a wartość nie może być mniejsza niż 0."
                    required
                  />
                </div>
                <div className={styles.addShotsSelect}>
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return {
                          ...prevState,
                          additionalShotPrice: e.target.value,
                        };
                      })
                    }
                    value={gallery.additionalShotPrice}
                    label="Cena dodatkowego ujęcia (zł)"
                    name="shotPrice"
                    id="shotPrice"
                    min="0"
                    type="number"
                    errorMessage="Cena dodatkowego ujęcia jest wymagana a wartość nie może być mniejsza lub równa 0."
                    required
                  />
                </div>
              </div>
            </div>
            <div className={styles.printingsData}>
              <h4>Wydruki</h4>
              <p>Jeśli sprzedajesz wydruki podaj cenę za 1 sztukę przy każdym sprzedawanym rozmiarze</p>
              <div className={styles.selectPrintings}>
                {PRINTINGS_PRICE.map((print) => (
                  <div key={print.size}>
                    <div>
                      <p style={{ fontWeight: "500" }}>{print.size} cm</p>
                    </div>
                    <div className={styles.printSelect}>
                      <input
                        onChange={(e) => {
                          print.setValue(e.target.value);
                        }}
                        value={print.value}
                        name="value"
                        id="value"
                        type="number"
                        min="0"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.confirmBox}>
            <PrimaryButton
              className={styles.confirmButton}
              title="Zapisz zmiany"
              backgroundColor="#0099F0"
              border="4px"
              color="#FFF"
            />
          </div>
        </form>
      </div>
      {/* <div className={styles.header}>
          <div onClick={() => navigate(`/galleries/${params.id}`)} className={styles.arrowBack}>
            <ArrowBack />
          </div>
          <div>
            <h3>Ustawienia galerii</h3>
            <p>
              W tym miejscu możesz edytować swoją galerię. Zmiany mogą być dokonywane tylko przed wysłaniem jej do
              klienta.
            </p>
          </div>
        </div>
        {loading ? (
          <LoadingBar />
        ) : (
          <form onSubmit={editGallery}>
            <div className={styles.selectClient}>
              <div className="row">
                <div className="col-12">
                  <FormSelect
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, customer: e.target.value };
                      })
                    }
                    name="customer"
                    id="customer"
                    label="Wybierz klienta z listy"
                    firstgroup="Wybrany klient"
                    secondgroup="Lista klientów"
                    selection={customer.name}
                    initialOption={customer.name}
                    values={customers}
                    pattern=".{1,}"
                    required
                  />
                </div>
              </div>
            </div>
            <div className={styles.primarySettings}>
              <div className="row">
                <div className="col-12">
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, title: e.target.value };
                      })
                    }
                    value={gallery.title}
                    type="text"
                    label="Nazwa galerii"
                    name="title"
                    pattern=".{1,50}"
                    errorMessage="Nazwa galerii jest wymagana i może zawierać maksymalnie 50 znaków."
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 pe-1">
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, date: e.target.value };
                      })
                    }
                    value={gallery.date}
                    label="Data wydarzenia"
                    name="date"
                    type="date"
                    errorMessage="Data wydarzenia jest wymagana."
                    required
                  />
                </div>
                <div className="col-6 ps-1">
                  <FormSelect
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, type: e.target.value };
                      })
                    }
                    name="type"
                    id="type"
                    label="Rodzaj sesji"
                    values={SESSION_TYPES}
                    initialOption={gallery.type}
                    selection={gallery.type}
                    pattern=".{1,}"
                    errorMessage="Rodzaj sesji jest wymagany."
                    required
                  />
                </div>
              </div>
            </div>

            <div className="price-settings">
              <div className="row">
                <div className="col-6 pe-1">
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, price: e.target.value };
                      })
                    }
                    value={gallery.price || 0}
                    label="Cena sesji (zł)"
                    name="price"
                    min="0"
                    type="number"
                    errorMessage="Cena sesji jest wymagana a wartość nie może być mniejsza niż 0."
                    required
                  />
                </div>
                <div className="col-6 ps-1">
                  <FormInput
                    onChange={(e) =>
                      setGallery((prevState) => {
                        return { ...prevState, paid: e.target.value };
                      })
                    }
                    value={gallery.paid || 0}
                    label="Zapłacono (zł)"
                    name="paid"
                    id="paid"
                    min="0"
                    max={gallery.price}
                    type="number"
                    errorMessage="Wartość nie może być większa od ceny sesji i mniejsza niż 0."
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-6 pe-1">
                    <FormInput
                      onChange={(e) =>
                        setGallery((prevState) => {
                          return { ...prevState, shotsQt: e.target.value };
                        })
                      }
                      value={gallery.shotsQt}
                      label="Ilość ujęć w cenie sesji"
                      name="shots"
                      id="shots"
                      min="0"
                      type="number"
                      errorMessage="Ilość ujęć jest wymagana a wartość nie może być mniejsza niż 0."
                      required
                    />
                  </div>
                  <div className="col-6 ps-1">
                    <FormInput
                      onChange={(e) =>
                        setGallery((prevState) => {
                          return {
                            ...prevState,
                            additionalShotPrice: e.target.value,
                          };
                        })
                      }
                      value={gallery.additionalShotPrice}
                      label="Cena dodatkowego ujęcia (zł)"
                      name="shotPrice"
                      id="shotPrice"
                      min="0"
                      type="number"
                      errorMessage="Cena dodatkowego ujęcia jest wymagana a wartość nie może być mniejsza lub równa 0."
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.printingsInput}>
              <label htmlFor="ifPrintings">Sprzedaję wydruki</label>
              <input
                onChange={(e) =>
                  setGallery((prevState) => {
                    return { ...prevState, ifPrintings: !gallery.ifPrintings };
                  })
                }
                value={gallery.ifPrintings}
                checked={gallery.ifPrintings}
                name="ifPrintings"
                id="ifPrintings"
                type="checkbox"
              />
            </div>
            <div className={styles.printingsPrice}>
              {gallery.ifPrintings ? (
                <>
                  <div>
                    <div className="row">
                      <div className="col-4">
                        <label>Rozmiar (cm)</label>
                      </div>
                      <div className="col-6">
                        <label>Cena za sztukę (zł)</label>
                      </div>
                    </div>
                    <div className="row my-1">
                      <div className="col-4">
                        <p>{gallery.additionalPrintings[0].size}</p>
                      </div>
                      <div className="col-4 pe-1">
                        <input
                          onChange={(e) => setPrice1015(e.target.value)}
                          value={0 || price1015}
                          name="value"
                          id="value"
                          type="number"
                        />
                      </div>
                      <div className="col-6 ps-1"></div>
                    </div>
                  </div>
                  <div>
                    <div className="row my-1">
                      <div className="col-4">
                        <p>{gallery.additionalPrintings[1].size}</p>
                      </div>
                      <div className="col-4 pe-1">
                        <input
                          onChange={(e) => setPrice1521(e.target.value)}
                          value={"" || price1521}
                          name="value"
                          id="value"
                          type="number"
                        />
                      </div>
                      <div className="col-6 ps-1"></div>
                    </div>
                  </div>
                  <div>
                    <div className="row my-1">
                      <div className="col-4">
                        <p>{gallery.additionalPrintings[2].size}</p>
                      </div>
                      <div className="col-4 pe-1">
                        <input
                          onChange={(e) => setPrice2030(e.target.value)}
                          value={"" || price2030}
                          name="value"
                          id="value"
                          type="number"
                        />
                      </div>
                      <div className="col-6 ps-1"></div>
                    </div>
                  </div>
                  <div>
                    <div className="row my-1">
                      <div className="col-4">
                        <p>{gallery.additionalPrintings[3].size}</p>
                      </div>
                      <div className="col-4 pe-1">
                        <input
                          onChange={(e) => setPrice3040(e.target.value)}
                          value={"" || price3040}
                          name="value"
                          id="value"
                          type="number"
                        />
                      </div>
                      <div className="col-6 ps-1"></div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className={styles.confirmBox}>
              <button className={styles.confirmButton}>Zatwierdź</button>
            </div>
          </form>
        )}
      </div> */}
      {message ? <Alert message={message} close={() => setMessage("")} /> : ""}
    </Page>
  );
};

export default GallerySettingsPage;
