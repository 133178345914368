import styles from "./statusPill.module.scss";

const StatusPill = ({ status }) => {
  const pill = () => {
    if (status === "new") {
      return (
        <div className={styles.statusPill} style={{ backgroundColor: "#FFFBA1" }}>
          <p>nowa</p>
        </div>
      );
    } else if (status === "sent") {
      return (
        <div className={styles.statusPill} style={{ backgroundColor: "#FFC8A0" }}>
          <p>wysłana</p>
        </div>
      );
    } else if (status === "selected") {
      return (
        <div className={styles.statusPill} style={{ backgroundColor: "#96CFF0" }}>
          <p>wybrana</p>
        </div>
      );
    } else if (status === "edited") {
      return (
        <div className={styles.statusPill} style={{ backgroundColor: "#AFC9A6" }}>
          <p>gotowa</p>
        </div>
      );
    } else if (status === "done") {
      return (
        <div className={styles.statusPill} style={{ backgroundColor: "#DAB8EA" }}>
          <p>gotowa</p>
        </div>
      );
    }
  };
  return (
    <div className={styles.container}>
      <div>{pill()}</div>
    </div>
  );
};

export default StatusPill;
