import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../../../store/AppContextProvider";
import FileDownload from "js-file-download";
import styles from "./galleryResume.module.scss";
import axios from "axios";
import useCloseOutside from "../../../helpers/closeOutside";
import ArrowBack from "../../../static/svg/ArrowBack";
import Page from "../../../components/layout/page/Page";
import SettingsIcon from "../../../static/svg/SettingsIcon";
import ResumeSettings from "./components/resumeSettings/ResumeSettings";
import ConfirmationBox from "../../../components/layout/ConfirmationBox";
import Alert from "../../../components/layout/Alert/Alert";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import ResumeInfoTable from "./components/resumeInfoTable/ResumeInfoTable";
import LoadingBar from "../../../components/layout/LoadingBar";
import Selections from "./components/selections/Selections";

const GalleryResume = (props) => {
  const [gallery, setGallery] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [customer, setCustomer] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [shotsPrice, setShotsPrice] = useState(0);
  const [printingsPrice, setPrintingsPrice] = useState();
  const [remain, setRemain] = useState();
  const [selectedPhotos, setSelectedPhotos] = useState(false);
  const [selections, setSelections] = useState([]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [needConfirmation, setNeedConfirmation] = useState(false);
  const [question, setQuestion] = useState("");
  const [action, setAction] = useState();
  const [resumeSettingsTrigger, setResumeSettingsTrigger] = useState(false);

  const { user, setUser } = useContext(UserContext);

  let navigate = useNavigate();
  let params = useParams();
  const popup = useRef();

  useEffect(() => {
    getGallery();
    getCustomer();
    if (gallery) {
      if (gallery.selectedShotsQt > gallery.shotsQt) {
        setShotsPrice((gallery.selectedShotsQt - gallery.shotsQt) * gallery.additionalShotPrice);
      }
      let printingsValue = 0;
      gallery.photos.forEach((photo) => {
        printingsValue = printingsValue + photo.printingsPrice;
      });
      setPrintingsPrice(printingsValue);
    }
  }, [customerID]);

  const getGallery = async () => {
    setLoading(true);
    await axios.get(`/galleries/${params.id}`).then((res) => {
      setGallery(res.data);
      setPhotos(res.data.photos);
      setCustomerID(res.data.customer);
      let selectedPhotos = res.data.photos.filter((photo) => photo.selected === true);
      setSelectedPhotos(selectedPhotos);
      axios.get(`/selections/${res.data._id}`).then((res) => {
        setSelections(res.data);
      });
    });
    setLoading(false);
  };

  const getCustomer = async () => {
    if (customerID) {
      await axios.get(`/customers/${customerID}`).then((res) => {
        setCustomer(res.data);
      });
    }
  };

  const downloadResume = async () => {
    const data = {
      title: gallery.title,
      customer: customer.name,
      resumeID: gallery._id,
      selections: selections,
    };

    await axios({
      method: "POST",
      url: `/galleries/download-resume/${params.id}`,
      data: data,
    })
      .then((res) => {})
      .catch((error) => console.log(error));

    await axios({
      method: "GET",
      url: `/galleries/download-resume/${params.id}`,
      responseType: "blob",
    })
      .then((res) => {
        FileDownload(res.data, `${gallery.title}.txt`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirmAction = (question, action) => {
    setQuestion(question);
    setNeedConfirmation(true);
    setAction(() => action);
  };

  useCloseOutside(popup, () => setResumeSettingsTrigger(false));

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.returnButton}>
          <ReturnButton title="Powrót do galerii" url={`/galleries/${params.id}`} />
        </div>
        {loading ? (
          <LoadingBar />
        ) : (
          <>
            <div className={styles.headerTitle}>
              <h3>Podsumowanie</h3>
              <div onClick={() => setResumeSettingsTrigger(true)} className={styles.resumeSettingsIcon}>
                <BsThreeDotsVertical size={25} color="#191919" />
              </div>
            </div>
            <ResumeInfoTable gallery={gallery} shotsPrice={shotsPrice} printingsPrice={printingsPrice} />
            <Selections selections={selections} title={gallery.title} />
          </>
        )}
      </div>
      {/* <div className={styles.titleHeader}>
          <div className={styles.pageDescription}>
            <div onClick={() => navigate(`/galleries/${gallery._id}`)}>
              <ArrowBack />
            </div>
            <div>
              <h3>Podsumowanie</h3>
              <p>Poniżej znajduje się lista ujęć oraz wydruków, które zostały wybrane przez klienta.</p>
            </div>
          </div>
          <div className={styles.resumeSettingsIcon} onClick={() => setResumeSettingsTrigger(true)}>
            <SettingsIcon color="#151414" width="25" height="25" />
          </div>
        </div>
        <div className={styles.summary}>
          <p>Całkowita cena sesji (podstawowa cena sesji + dodatkowe ujęcia + wydruki): {totalPrice()} </p>
          <p>
            Zapłacono: <span>{gallery.paid} zł</span>
          </p>
          <p>Pozostała kwota do zapłaty: {remainingPrice()}</p>
          <p>
            Liczba wybranych ujęć: <span>{gallery.selectedShotsQt}</span>
          </p>
          <div className={styles.printingsSummary}>
            <p>Wybrane wydruki: </p>
            <ul>
              {gallery
                ? gallery.additionalPrintings.map((printing) => (
                    <div key={printing._id}>
                      {printingsCount(printing) ? (
                        <li>
                          <p>{printing.size} cm</p>
                          <span>{printingsCount(printing)} szt</span>
                        </li>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                : ""}
            </ul>
          </div>

          <div className={styles.confirmBox}>
            <div className={styles.selectionsMenu}>
              {selections.map((selection, index) => (
                <div key={index} onClick={() => setSelectionNumber(index)} className={styles.selectionButton}>
                  <span style={index == selectionNumber ? { color: "#0096ea" } : {}}>{`Wybór ${index + 1}`}</span>
                </div>
              ))}
            </div>

            {gallery.status != "new" && (
              <div onClick={downloadResume} className={styles.confirmButton}>
                Pobierz listę z wyborem
              </div>
            )}
          </div>
          {gallery.status != "new" ? (
            <p>Data dokonania wyboru: {selections[selectionNumber]?.createdAt.slice(0, 10)}</p>
          ) : (
            <p>Nie dokonano wyboru</p>
          )}
          <div className={styles.selection}>
            {selections[selectionNumber] &&
              selections[selectionNumber].photos.map((photo) => (
                <div key={photo._id} className={styles.selectedPhoto}>
                  <div className={styles.cover}>
                    <a href={photo.url} target="blank">
                      <img src={photo.url || photo} alt="" />
                    </a>
                    {photo.title.length > 22 ? (
                      <p className={styles.title}>
                        {photo.title.substring(0, 10)}...
                        {photo.title.substr(photo.title.length - 10)}
                      </p>
                    ) : (
                      <p className={styles.title}>{photo.title}</p>
                    )}
                  </div>

                  <div>
                    <div className={styles.printingsList}>
                      {photo.printingsPrice ? (
                        <>
                          {photo.printings.map((printing) => (
                            <div key={printing._id} className="ps-4">
                              {printing.quantity ? (
                                <div className={styles.printing}>
                                  <p className={styles.size}>{printing.size}</p>
                                  <span>{printing.quantity} szt</span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                        </>
                      ) : (
                        "Nie wybrano wydruków"
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div> */}

      {needConfirmation && (
        <ConfirmationBox
          positiveAction={() => action()}
          negativeAction={() => {
            setNeedConfirmation(false);
          }}
          question={question}
        />
      )}
      {resumeSettingsTrigger && (
        <ResumeSettings
          confirmAction={confirmAction}
          close={() => setResumeSettingsTrigger(false)}
          gallery={gallery}
          getGallery={getGallery}
          photos={photos}
        />
      )}
      {message ? <Alert message={message} close={() => setMessage("")} /> : ""}
    </Page>
  );
};

export default GalleryResume;
