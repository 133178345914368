function SettingsIcon({ color, width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28_432)">
        <path
          d="M20.35 13.2L19.25 12.5583C18.0583 11.825 18.0583 10.0833 19.25 9.35L20.35 8.70833C21.2667 8.15833 21.5417 7.05833 20.9917 6.23333L20.075 4.675C19.525 3.75833 18.425 3.48333 17.6 4.03333L16.5 4.675C15.3083 5.40833 13.75 4.49167 13.75 3.11667V1.83333C13.75 0.825 12.925 0 11.9167 0H10.0833C9.075 0 8.25 0.825 8.25 1.83333V3.025C8.25 4.4 6.69167 5.31667 5.5 4.58333L4.4 4.03333C3.48333 3.48333 2.38333 3.85 1.925 4.675L1.00833 6.23333C0.55 7.15 0.825 8.25 1.65 8.8L2.75 9.44167C3.94167 10.0833 3.94167 11.9167 2.75 12.5583L1.65 13.2C0.733333 13.75 0.458333 14.85 1.00833 15.675L1.925 17.2333C2.475 18.15 3.575 18.425 4.4 17.875L5.5 17.325C6.69167 16.5917 8.25 17.5083 8.25 18.8833V20.1667C8.25 21.175 9.075 22 10.0833 22H11.9167C12.925 22 13.75 21.175 13.75 20.1667V18.975C13.75 17.6 15.3083 16.6833 16.5 17.4167L17.6 18.0583C18.5167 18.6083 19.6167 18.2417 20.075 17.4167L20.9917 15.8583C21.45 14.85 21.175 13.75 20.35 13.2ZM11 14.6667C8.98333 14.6667 7.33333 13.0167 7.33333 11C7.33333 8.98333 8.98333 7.33333 11 7.33333C13.0167 7.33333 14.6667 8.98333 14.6667 11C14.6667 13.0167 13.0167 14.6667 11 14.6667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_28_432">
          <rect width="22" height="22" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SettingsIcon;
