import { useState, useEffect, useContext } from "react";
import styles from "./dashboard.module.scss";
import { UserContext } from "../../store/AppContextProvider";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Page from "../../components/layout/page/Page";
import GalleriesList from "../../components/galleries/galleryList/GalleryList";
import PlusIcon from "../../static/svg/PlusIcon";
import LoadingBar from "../../components/layout/LoadingBar";
import CreateFirstGalleryImage from "../../static/svg/CreateFirstGalleryImage";
import PrimaryButton from "../../components/layout/primaryButton/PrimaryButton";

const DashboardPage = (props) => {
  const [galleries, setGalleries] = useState([]);
  const [selectedGalleries, setSelectedGalleries] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    getSelectedGalleries();
  }, []);

  const getSelectedGalleries = async () => {
    setLoading(true);
    await axios.get("/galleries").then((res) => {
      const selectedGalleries = res.data.filter((gallery) => {
        return gallery.status === "selected";
      });
      setSelectedGalleries(selectedGalleries);
      setGalleries(res.data);
    });
    setLoading(false);
  };

  const renamedGallery = () => {
    if (user.available_sessions === 1) {
      return `Na Twoim koncie pozostała ${user.available_sessions} galeria do
      wykorzystania`;
    } else if (user.available_sessions > 1 && user.available_sessions < 5) {
      return `Na Twoim koncie pozostały ${user.available_sessions} galerie do
      wykorzystania`;
    } else {
      return `Na Twoim koncie pozostało ${user.available_sessions} galerii do
      wykorzystania`;
    }
  };

  const PendingGalleries = () => {
    if (galleries.length === 0) {
      return (
        <div className={styles.createFirstGallery}>
          <CreateFirstGalleryImage />
          <h3 className={styles.createGalleryHeader}>Stwórz swoją pierwszą galerię i rozpocznij pracę</h3>
          <NavLink to="/galleries/new-gallery">
            <PrimaryButton title="Dodaj galerię" backgroundColor="#0099f0" border="none" color="#FFF" />
          </NavLink>
        </div>
      );
    } else if (selectedGalleries && selectedGalleries.length > 0) {
      return <GalleriesList galleries={selectedGalleries} />;
    } else if (selectedGalleries.length === 0) {
      return (
        <div className={styles.zeroPendingGalleries}>
          <h5 className={styles.infoHeader}>Oczekujące galerie</h5>
          <div className={styles.infoBox}>
            <span>Aktualnie nie posiadasz oczekujących galerii.</span>
          </div>
        </div>
      );
    }
  };

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.helloHeader}>
            <h3>Cześć {user.name}!</h3>
            {user.available_sessions || user.available_sessions <= 0 ? (
              <p>{renamedGallery()}</p>
            ) : (
              <p>
                nie masz juz dostępnych sesji.{" "}
                <Link style={{ fontWeight: "bold" }} to={"/packages"}>
                  Wykup pakiet
                </Link>
              </p>
            )}
          </div>
          <PrimaryButton
            onClick={() => navigate("/galleries/new-gallery")}
            title="Dodaj galerię"
            backgroundColor="#0099f0"
            border="1px solid #0099f0"
            color="#FFF"
          />
        </div>
        <div className={styles.selectedGalleries}>{loading ? <LoadingBar /> : <PendingGalleries />}</div>
      </div>
    </Page>
  );
};

export default DashboardPage;
