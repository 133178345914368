import { useState } from "react";
import CloseButton from "../../../../../static/svg/CloseButton";
import styles from "./resumeSettings.module.scss";
import axios from "axios";
import { useParams } from "react-router-dom";

const ResumeSettings = (props) => {
  const [payment, setPayment] = useState(0);

  const params = useParams();

  const confirmPayment = async () => {
    const paid = props.gallery.paid + Number(payment);

    const data = {
      paid: paid,
    };

    await axios
      .put(`/galleries/payment/${params.id}`, data)
      .then((res) => {
        props.getGallery();
      })
      .catch((error) => console.log(error));
  };

  const closeGallery = async () => {
    const data = {
      photos: props.photos,
      status: "done",
    };

    await axios
      .put(`/galleries/${params.id}`, data)
      .then((res) => {})
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.background} onClick={props.close}></div>
      <div className={styles.settingsModal}>
        {/* Icon with closing modal functionality */}
        <div className={styles.closeButton} onClick={props.close}>
          <CloseButton color="#151414" width="22" height="22" />
        </div>
        {/* A list with gallery resume settings */}
        <ul className={styles.settingsList}>
          <li className={styles.setting}>
            <label htmlFor="price">Wprowadź zapłaconą kwotę:</label>
            <div className={styles.inputWrapper}>
              <input
                onChange={(e) => setPayment(e.target.value)}
                onFocus={(e) => setPayment("")}
                value={payment}
                type="number"
                name="price"
                min="0"
              />
              <button
                onClick={() => {
                  props.close();
                  props.confirmAction(
                    `Czy potwierdzasz zapłatę ${payment} zł? Po jej zatwierdzeniu nie będzie możliwości edycji.`,
                    confirmPayment
                  );
                }}
                className={styles.confirmButton}
              >
                Potwierdzam
              </button>
            </div>
          </li>
          <li className={styles.setting}>
            <div className={styles.inputWrapper}>
              <p>Zakończ galerię</p>
              <button
                onClick={() => {
                  props.close();
                  props.confirmAction(
                    `Czy na pewno chcesz zamknąć galerię? Po jej zakończeniu nie będzie możliwości edycji.`,
                    closeGallery
                  );
                }}
                className={styles.confirmButton}
              >
                Potwierdzam
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ResumeSettings;
