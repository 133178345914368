import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const GalleryCard = styled.div`
  cursor: pointer;
  height: 420px;
  width: 100%;
  max-width: 360px;
  padding: 20px;
  background-color: #e8e8e8;
  .cover {
    height: 320px;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  h4 {
    padding-top: 10px;
  }
`;

const CustomerGalleryItem = (props) => {
  let navigate = useNavigate();

  const openGallery = () => {
    navigate(`/customer-panel/gallery/${props.gallery._id}`);
  };

  return (
    <GalleryCard onClick={openGallery}>
      <div className="cover">
        {}
        <img src={props.gallery.photos[0]?.url} alt="cover" />
      </div>
      <div>
        <h4>{props.gallery.title}</h4>
        <p>Data: {props.gallery.date}</p>
      </div>
    </GalleryCard>
  );
};

export default CustomerGalleryItem;
