import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../static/svg/Logo";

const PageWrapper = styled.div`
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 3vw 0 3vw;
    button {
      width: 80px;
      background: white;
      padding: 5px;
      font-size: 14px;
    }
  }
`;

const PageContent = styled.div`
  background: #fff;
  margin: 10px 10px 0 10px;
  min-height: calc(100vh - 60px);
  padding: 30px 15px;
  box-shadow: 6px 7px 9px -1px rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  flex: 1;
`;

const CustomerPage = (props) => {
  let navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("organization");
    navigate(`/customer-login`);
  };

  return (
    <PageWrapper>
      <div className="navbar">
        <NavLink to="/customer-panel">
          <Logo />
        </NavLink>
        <button onClick={logout}>Wyloguj</button>
      </div>
      <PageContent>{props.children}</PageContent>
    </PageWrapper>
  );
};

export default CustomerPage;
