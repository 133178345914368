import { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../store/AppContextProvider";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Page from "../../components/layout/page/Page";
import LoadingBar from "../../components/layout/LoadingBar";

const SettingsWrapper = styled.div`
  .profile {
    border-bottom: 4px solid #f2f2f2;
    .profile-details {
      padding: 10px 0;
      margin-bottom: 10px;
      .profile-label {
        font-weight: 500;
        background: #e5e5e5;
        padding: 1px 10px;
      }
      .profile-input {
        padding: 3px 15px;
      }
    }
    @media (min-width: 768px) {
      border-right: 4px solid #f2f2f2;
      border-bottom: none;
      padding-right: 10px;
    }
  }

  .watermark {
    padding-top: 20px;
    @media (min-width: 768px) {
      padding-top: 0;
      padding-left: 10px;
    }
    button {
      height: 30px;
      width: 100px;
      background: #151414;
      color: #ececec;
      font-size: 12px;
      font-weight: 600;
      border-radius: 2px;
    }
    .preview {
      margin-left: 10px;
      width: 250px;
      height: 370px;
      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
      }
    }
  }
`;

const UserSettingsPage = (props) => {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    getUser();
  }, []);

  const photosRef = useRef();
  let params = useParams();

  const getUser = async () => {
    setLoading(true);
    await axios
      .get("/users")
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => error);
    setLoading(false);
  };

  return (
    <Page>
      {loading ? (
        <LoadingBar />
      ) : (
        <SettingsWrapper>
          <div className="row">
            <div className="profile col-12 col-md-6">
              <h3>Profil</h3>
              <p>Tutaj znajdziesz informacje zawarte w Twoim profilu</p>
              <div className="profile-details">
                <p className="profile-label">Nazwa organizacji/firmy</p>
                <p className="profile-input">{user.organization_name}</p>
                <p className="profile-label">Imię i nazwisko</p>
                <p className="profile-input">{user.name}</p>
                <p className="profile-label">Adres email</p>
                <p className="profile-input">{user.email}</p>
              </div>
            </div>
            <div className="watermark col-12 col-md-6"></div>
          </div>
        </SettingsWrapper>
      )}
    </Page>
  );
};

export default UserSettingsPage;
