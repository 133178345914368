import { useContext, useState } from "react";
import styles from "./bundlesPage.module.scss";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Page from "../../../components/layout/page/Page";
import Alert from "../../../components/layout/Alert/Alert";
import OrderConfirm from "../components/OrderConfirm";
import { UserContext } from "../../../store/AppContextProvider";
import { Link } from "react-router-dom";

const BundlesPage = (props) => {
  const { user, setUser } = useContext(UserContext);
  const [orderConfirm, setOrderConfirm] = useState(false);
  const [message, setMessage] = useState("");

  const [order, setOrder] = useState({
    type: String,
    orderId: String,
    name: String,
    email: String,
    price: Number,
  });

  const BUNDLES = [
    {
      type: "Pakiet 1",
      description: "1 galeria",
      price: 15,
      galleriesQuantity: 1,
    },
    {
      type: "Pakiet 2",
      description: "5 galerii",
      price: 45,
      galleriesQuantity: 5,
    },
    {
      type: "Pakiet 3",
      description: "10 galerii",
      price: 60,
      galleriesQuantity: 10,
    },
  ];

  const closeOrderConfirm = () => {
    setOrderConfirm(false);
    setOrder({
      type: String,
      galleriesQuantity: Number,
      orderId: String,
      name: String,
      email: String,
      price: Number,
    });
  };

  return (
    <Page>
      <div className={styles.container}>
        <p>Spodobała Ci się nasza aplikacja i potrzebujesz dodatkowych galerii?</p>
        <p>
          Skontaktuj się z nami poprzez{" "}
          <Link to="/contact">
            <span>formularz kontaktowy</span>
          </Link>{" "}
          i zgłoś chęć zostania jednym z pierwszych testerów Maslado. Otrzymasz pełny dostęp do wszystkich funkcji w
          aplikacji wraz z pakietem 20 darmowych galerii.
        </p>
        {/* {BUNDLES.map((bundle) => (
          <div className={styles.bundleCard} key={bundle.type}>
            <h3>{bundle.type}</h3>
            <div>
              <p>{bundle.description}</p>
              <p>{bundle.price} zł</p>
            </div>
            <button
              onClick={() => {
                setOrderConfirm(true);
                setOrder({
                  type: bundle.type,
                  galleriesQuantity: bundle.galleriesQuantity,
                  orderId: uuidv4(),
                  name: user.name,
                  email: user.email,
                  price: bundle.price,
                });
              }}
            >
              Wybieram
            </button>
          </div>
        ))} */}
      </div>
      {orderConfirm && <OrderConfirm order={order} close={closeOrderConfirm} />}
      {message ? <Alert message={message} close={() => setMessage("")} /> : ""}
    </Page>
  );
};

export default BundlesPage;
