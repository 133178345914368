import axios from "axios";
import styles from "./customerGalleryPage.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../store/AppContextProvider";
import { saveAs } from "file-saver";

import CustomerPage from "../../../components/layout/CustomerPage";
import PrintingsChart from "./components/PrintingsChart";
import LoadingBar from "../../../components/layout/LoadingBar";
import CustomerGalleryCarousel from "../../../components/customer-panel/customer-gallery-carousel/CustomerGalleryCarousel";
import ConfirmationBox from "../../../components/layout/ConfirmationBox";
import ArrowBack from "../../../static/svg/ArrowBack";
import ArrowDown from "../../../static/svg/ArrowDown";
import Alert from "../../../components/layout/Alert/Alert";
import QuestionMarkIcon from "../../../static/svg/QuestionMarkIcon";
import ModalDownloadPhotos from "./components/modalDownloadPhotos/ModalDownloadPhotos";

const CustomerGalleryPage = (props) => {
  const [gallery, setGallery] = useState({});
  const [customer, setCustomer] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [title, setTitle] = useState("");
  const [shotsQt, setShotsQt] = useState(0);
  const [selectedShotsQt, setSelectedShotsQt] = useState("");
  const [markedShotsQt, setMarkedShotsQt] = useState("");
  const [photos, setPhotos] = useState([]);
  const [markedPhotos, setMarkedPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [editedPhotos, setEditedPhotos] = useState([]);
  const [selectionPhotos, setSelectionPhotos] = useState([]);
  const [showFilteredPhotos, setShowFilteredPhotos] = useState("all");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [price, setPrice] = useState(0);
  const [additionalShotPrice, setAdditionalShotPrice] = useState(0);
  const [additionalPrintings, setAdditionalPrintings] = useState([]);
  const [paid, setPaid] = useState(0);
  const [loading, setLoading] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [question, setQuestion] = useState("");
  const [action, setAction] = useState();
  const [needConfirmation, setNeedConfirmation] = useState(false);
  const [index, setIndex] = useState(1);
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [downloadURL, setDownloadURL] = useState("");

  const { user, setUser } = useContext(UserContext);

  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getGallery();
    getCustomer();
    updateViewDate();
    setLoading(false);
  }, [customerID]);

  const getGallery = async () => {
    await axios.get(`/galleries/${params.id}`).then((res) => {
      setGallery(res.data);
      setTitle(res.data.title);
      if (gallery.status != "edited") {
        let x = res.data.photos.filter((photo) => photo.edited === false);
        setPhotos(x);
      } else {
        setPhotos(res.data.photos);
      }
      // let editedPhotos = photos.filter((photo) => photo.edited === true);
      setEditedPhotos(res.data.editedPhotos);
      setPrice(res.data.price);
      setPaid(res.data.paid);
      setShotsQt(res.data.shotsQt);
      setSelectedShotsQt(res.data.selectedShotsQt);
      setMarkedShotsQt(res.data.markedShotsQt);
      setAdditionalShotPrice(res.data.additionalShotPrice);
      setAdditionalPrintings(res.data.additionalPrintings);
      setCustomerID(res.data.customer);
    });
  };

  const getCustomer = async () => {
    await axios
      .get(`/customers/${customerID}`)
      .then((res) => setCustomer(res.data))
      .catch((error) => console.log(error));
  };

  const updateViewDate = async () => {
    let today = new Date();
    let date = today.getDate() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getFullYear();

    let updatedGallery = {
      viewDate: date,
    };

    await axios
      .put(`/galleries/viewDate/${params.id}`, updatedGallery)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const selectPhoto = async (photo) => {
    const updatedPhotos = photos.map((obj) => {
      if (obj.id === photo.id) {
        return { ...obj, marked: true };
      }
      return obj;
    });

    const data = {
      markedShotsQt: markedShotsQt + 1,
      photos: updatedPhotos,
    };

    await axios
      .put(`/galleries/${params.id}`, data)
      .then((res) => {
        setMarkedShotsQt(markedShotsQt + 1);
        getGallery();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unselectPhoto = async (photo) => {
    const updatedPhotos = photos.map((obj) => {
      if (obj.id === photo.id) {
        const resetPrintings = photo.printings.map((printing) => {
          return printing.quantity > 0 ? { ...printing, quantity: 0 } : printing;
        });

        return {
          ...obj,
          marked: false,
          showPrintings: false,
          printings: resetPrintings,
          printingsPrice: 0,
        };
      }
      return obj;
    });

    const data = {
      markedShotsQt: markedShotsQt - 1,
      photos: updatedPhotos,
    };

    await axios
      .put(`/galleries/${params.id}`, data)
      .then((res) => {
        setMarkedShotsQt(markedShotsQt - 1);
        getGallery();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openPrintingsChart = async (index) => {
    let photosCopy = [...photos];
    let photoCopy = { ...photosCopy[index] };
    photoCopy.showPrintings = !photoCopy.showPrintings;

    photosCopy[index] = photoCopy;
    setPhotos(photosCopy);
  };

  const updatePrintings = async (index, updatedPhoto) => {
    updatedPhoto = { ...updatedPhoto, showPrintings: false };
    console.log(updatedPhoto);
    let updatedPhotos = [...photos];
    updatedPhotos[index] = updatedPhoto;

    setSelectionPhotos(updatedPhotos);

    const data = {
      photos: updatedPhotos,
    };

    await axios
      .put(`/galleries/${params.id}`, data)
      .then((res) => {
        getGallery();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendGallery = async () => {
    setNeedConfirmation(false);
    setLoading(true);

    const selectedPhotos = [];
    photos.map((photo) => {
      if (photo.marked === true) {
        selectedPhotos.push(photo);
      }
    });

    if (selectedPhotos.length <= 0) {
      setLoading(false);
      return setMessage("Nie dokonano wyboru");
    }

    const newSelection = {
      gallery: gallery._id,
      photos: selectedPhotos,
    };

    await axios
      .post("/selections", newSelection)
      .then((res) => {
        const data = {
          galleryTitle: title,
          customerName: customer.name,
          organizationEmail: customer.organizationEmail,
        };

        const updatedPhotos = photos.map((photo) => {
          if (photo.marked === true) {
            return { ...photo, marked: false, selected: true };
          }
          return photo;
        });

        const newGallery = {
          ...gallery,
          photos: updatedPhotos,
          editedPhotos: editedPhotos,
          selectedShotsQt: selectedShotsQt + selectedPhotos.length,
          status: "selected",
        };

        axios
          .post("/galleries/send-to-photographer", data)
          .then((res) => {
            axios
              .put(`/galleries/${params.id}`, newGallery)
              .then((res) => {
                setMessage("Wysłano wybór do fotografa");
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((error) => {
            console.log(error);
          });
        navigate(`/customer-panel?message=Wysłano wybór do fotografa`);
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      });

    setLoading(false);
  };

  const totalPrice = () => {
    if (markedShotsQt <= 0) {
      return <span>{price - paid} zł</span>;
    } else if (markedShotsQt > 0) {
      let printingsPrice = 0;
      photos.forEach((photo) => {
        return (printingsPrice = printingsPrice + photo.printingsPrice);
      });

      if (markedShotsQt - shotsQt <= 0) {
        return <span>{price - paid + printingsPrice} zł</span>;
      } else {
        return <span>{(markedShotsQt - shotsQt) * additionalShotPrice + (price - paid) + printingsPrice} zł</span>;
      }
    }
  };

  const printingsPrice = () => {
    if (markedShotsQt <= 0) {
      return 0;
    } else if (markedShotsQt > 0) {
      let printingsPrice = 0;
      photos.forEach((photo) => {
        return (printingsPrice = printingsPrice + photo.printingsPrice);
      });
      return printingsPrice;
    }
  };

  const openGalleryModal = (index) => {
    setIndex(index);
    setGalleryModal(true);
  };

  const closeGalleryModal = () => {
    setGalleryModal(false);
  };

  const confirmAction = (question, action) => {
    setQuestion(question);
    setNeedConfirmation(true);
    setAction(() => action);
  };

  const photoButtons = (photo) => {
    if (photo.marked) {
      return (
        <button
          onClick={() => {
            unselectPhoto(photo);
          }}
          className={styles.selectPhoto}
          style={{ background: "#83F29C" }}
        >
          Wybrano
        </button>
      );
    } else if (photo.selected) {
      return (
        <button className={styles.selectPhoto} style={{ background: "grey", width: "180px" }}>
          Wysłano do fotografa
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            selectPhoto(photo);
          }}
          className={styles.selectPhoto}
        >
          Wybieram
        </button>
      );
    }
  };

  const downloadSinglePhoto = async (photo) => {
    saveAs(photo.url, `${photo.title}.jpg`);
  };

  const downloadMultiplePhotos = async () => {
    const data = {
      id: gallery._id,
      photos: photos.filter((photo) => photo.edited === true),
      path: customer.organization_name,
    };

    await axios
      .post(`/upload/download`, data)
      .then((res) => {
        setModalOpen(true);
        setDownloadURL(res.data.url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filteredPhotos = () => {
    let editedPhotos = photos.filter((photo) => photo.edited === true);
    // setEditedPhotos(editedPhotos);

    let markedPhotos = photos.filter((photo) => photo.marked === true);
    // setMarkedPhotos(markedPhotos);

    let selectedPhotos = photos.filter((photo) => photo.selected === true);
    // setSelectedPhotos(selectedPhotos);

    if (showFilteredPhotos === "all") {
      return (
        <>
          {photos.map((photo, index) => (
            <div className={`${styles.photo} ${photo.edited ? styles.edited : ""}`} key={index}>
              <a onClick={() => openGalleryModal(index)} target="blank">
                <img src={photo.url || photo} alt="" />
              </a>
              {photo.edited ? (
                <>
                  <button onClick={() => downloadSinglePhoto(photo)} className={styles.downloadPhoto}>
                    Pobierz
                  </button>
                </>
              ) : (
                <>
                  {photoButtons(photo)}
                  {(photo.marked || photo.selected) & gallery.ifPrintings ? (
                    <button
                      onClick={() => openPrintingsChart(index)}
                      className={styles.openPrintings}
                      style={photo.printingsPrice ? { background: "#83F29C" } : { background: "inherit" }}
                    >
                      Wydruki
                    </button>
                  ) : (
                    ""
                  )}
                  {photo.showPrintings ? (
                    <PrintingsChart
                      photo={photo}
                      additionalPrintings={additionalPrintings}
                      closeChart={() => openPrintingsChart(index)}
                      func={(updatedPhoto) => updatePrintings(index, updatedPhoto)}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          ))}
        </>
      );
    } else if (showFilteredPhotos === "marked") {
      return (
        <>
          {markedPhotos.map((photo, index) => (
            <div className={styles.photo} key={index}>
              <a onClick={() => openGalleryModal(index)} target="blank">
                <img src={photo.url || photo} alt="" />
              </a>
              {photo.edited ? (
                <>
                  <button className={styles.downloadPhoto}>
                    <a
                      href={`https://res.cloudinary.com/roehill/image/upload/fl_attachment:${photo.title
                        .replace(/[^a-zA-Z0-9 ]/g, "")
                        .slice(0, -3)}/${photo.id}`}
                    >
                      Pobierz
                    </a>
                  </button>
                </>
              ) : (
                <>
                  {photoButtons(photo)}
                  {(photo.marked || photo.selected) & gallery.ifPrintings ? (
                    <button
                      onClick={() => openPrintingsChart(index)}
                      className={styles.openPrintings}
                      style={photo.printingsPrice ? { background: "#83F29C" } : { background: "grey" }}
                    >
                      Wydruki
                    </button>
                  ) : (
                    ""
                  )}
                  {photo.showPrintings ? (
                    <PrintingsChart
                      photo={photo}
                      additionalPrintings={additionalPrintings}
                      closeChart={() => openPrintingsChart(index)}
                      func={(updatedPhoto) => updatePrintings(index, updatedPhoto)}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          ))}
        </>
      );
    } else if (showFilteredPhotos === "selected") {
      return (
        <>
          {selectedPhotos.map((photo, index) => (
            <div className={styles.photo} key={index}>
              <a onClick={() => openGalleryModal(index)} target="blank">
                <img src={photo.url || photo} alt="" />
              </a>
              {photo.edited ? (
                <>
                  <button className={styles.downloadPhoto}>
                    <a
                      href={`https://res.cloudinary.com/roehill/image/upload/fl_attachment:${photo.title
                        .replace(/[^a-zA-Z0-9 ]/g, "")
                        .slice(0, -3)}/${photo.id}`}
                    >
                      Pobierz
                    </a>
                  </button>
                </>
              ) : (
                <>
                  {photoButtons(photo)}
                  {(photo.marked || photo.selected) & gallery.ifPrintings ? (
                    <button
                      onClick={() => openPrintingsChart(index)}
                      className={styles.openPrintings}
                      style={photo.printingsPrice ? { background: "#83F29C" } : { background: "grey" }}
                    >
                      Wydruki
                    </button>
                  ) : (
                    ""
                  )}
                  {photo.showPrintings ? (
                    <PrintingsChart
                      photo={photo}
                      additionalPrintings={additionalPrintings}
                      closeChart={() => openPrintingsChart(index)}
                      func={(updatedPhoto) => updatePrintings(index, updatedPhoto)}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          ))}
        </>
      );
    } else if (showFilteredPhotos === "edited") {
      return (
        <>
          {editedPhotos.map((photo, index) => (
            <div className={styles.photo} key={index}>
              <a onClick={() => openGalleryModal(index)} target="blank">
                <img src={photo.url || photo} alt="" />
              </a>

              {photo.edited ? (
                <>
                  <button className={styles.downloadPhoto}>
                    <a
                      href={`https://res.cloudinary.com/roehill/image/upload/fl_attachment:${photo.title
                        .replace(/[^a-zA-Z0-9 ]/g, "")
                        .slice(0, -3)}/${photo.id}`}
                    >
                      Pobierz
                    </a>
                  </button>
                </>
              ) : (
                <>
                  {photo.marked & gallery.ifPrintings ? (
                    <button
                      onClick={() => openPrintingsChart(index)}
                      className={styles.openPrintings}
                      style={photo.printingsPrice ? { background: "#83F29C" } : { background: "grey" }}
                    >
                      Wydruki
                    </button>
                  ) : (
                    ""
                  )}
                  {photo.showPrintings ? (
                    <PrintingsChart
                      photo={photo}
                      additionalPrintings={additionalPrintings}
                      closeChart={() => openPrintingsChart(index)}
                      func={(updatedPhoto) => updatePrintings(index, updatedPhoto)}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <CustomerPage>
      {galleryModal && (
        <CustomerGalleryCarousel
          showFilteredPhotos={showFilteredPhotos}
          closeGalleryModal={closeGalleryModal}
          photos={photos}
          index={index}
          selectPhoto={selectPhoto}
          unselectPhoto={unselectPhoto}
          openPrintingsChart={openPrintingsChart}
          additionalPrintings={additionalPrintings}
          updatePrintings={updatePrintings}
          selectedShotsQt={selectedShotsQt}
          totalPrice={totalPrice}
          ifPrintings={gallery.ifPrintings}
        ></CustomerGalleryCarousel>
      )}
      {modalOpen && <ModalDownloadPhotos downloadURL={downloadURL} closeModal={() => setModalOpen(false)} />}
      {loading ? (
        <LoadingBar />
      ) : (
        <div className={styles.customerGalleryWrapper}>
          <div className={styles.header}>
            <div onClick={() => navigate("/customer-panel")} className={styles.arrowBack}>
              <ArrowBack />
            </div>
            <div className={styles.title}>
              <h3>{title}</h3>
              <p>Wybierz swoje ujęcia i odeślij wybór do fotografa</p>
            </div>
          </div>
          <div className={styles.controls}>
            <div className={styles.chosenShots}>
              <p>
                Ilość zaznaczonych ujęć: <span>{markedShotsQt}</span>
              </p>
            </div>
            <div className={styles.totalPrice}>
              <p>Do zapłaty: {totalPrice()}</p>
              <div className={styles.icon}>
                <QuestionMarkIcon />
                <div className={styles.detailedPriceInfo}>
                  <p>Podstawowa cena sesji: {price} zł</p>
                  <p>
                    Cena dodatkowych ujęć:{" "}
                    {markedShotsQt - shotsQt <= 0 ? <>0</> : <>{(markedShotsQt - shotsQt) * additionalShotPrice}</>} zł
                  </p>
                  <p>Cena dodatkowych wydruków: {printingsPrice()} zł</p>
                  <p>Zapłacono: {paid} zł</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => confirmAction("Czy jesteś pewien, ze chcesz wysłać swój wybór fotografowi?", sendGallery)}
            >
              Wyślij wybór
            </button>
            {gallery.status === "edited" ? <button onClick={downloadMultiplePhotos}>Pobierz wszystkie</button> : ""}
            <div className={styles.filter} onClick={() => setShowFilterOptions(!showFilterOptions)}>
              Filtruj{" "}
              <span>
                <ArrowDown></ArrowDown>
              </span>
              <div
                className={styles.filterOptions}
                style={showFilterOptions ? { display: "block" } : { display: "none" }}
              >
                <ul>
                  <li onClick={() => setShowFilteredPhotos("all")}>wszystkie</li>
                  <li onClick={() => setShowFilteredPhotos("marked")}>zaznaczone</li>
                  <li onClick={() => setShowFilteredPhotos("selected")}>wybrane</li>
                  <li onClick={() => setShowFilteredPhotos("edited")}>gotowe</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.gallery}>{filteredPhotos()}</div>
        </div>
      )}
      {needConfirmation ? (
        <ConfirmationBox
          positiveAction={() => action()}
          negativeAction={() => {
            setNeedConfirmation(false);
          }}
          question={question}
        />
      ) : (
        ""
      )}
      {message ? <Alert message={message} close={() => setMessage("")} /> : ""}
    </CustomerPage>
  );
};

export default CustomerGalleryPage;
