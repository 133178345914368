import axios from "axios";
import ArrowBack from "../../../static/svg/ArrowBack";
import styles from "./orderConfirm.module.scss";

const OrderConfirm = ({ close, order }) => {
  const registerOrder = async () => {
    const data = {
      orderId: order.orderId,
      type: order.type,
      galleriesQuantity: order.galleriesQuantity,
      price: order.price,
      email: order.email,
      description: "Pakiet galerii",
    };

    await axios
      .post(`/orders/register-transaction`, data)
      .then((res) => {
        window.open(`https://secure.przelewy24.pl/trnRequest/${res.data.token}`, "_blank");
        close();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.topMenu}>
        <div onClick={close} className={styles.arrowBack}>
          <ArrowBack background="#FFF" />
          <span>Cofnij</span>
        </div>
      </div>
      <div className={styles.confirmCard}>
        <div className={styles.cardHeader}>
          <h6>Potwierdź szczegóły płatności</h6>
          <p>Po zatwierdzeniu zostaniesz przekierowany na stronę Przelewy24</p>
        </div>
        <ul>
          <li>
            <span>{order.type}</span>
            <p>Liczba dodatkowych galerii: {order.galleriesQuantity}</p>
          </li>
          <li>
            <span>Numer zamówienia:</span>
            <p>{order.orderId}</p>
          </li>
          <li>
            <span>Nazwa kupującego:</span>
            <p>{order.name}</p>
          </li>
          <li>
            <span>Adres email:</span>
            <p>{order.email}</p>
          </li>
          <li>
            <span>Kwota do zapłaty:</span>
            <p>{order.price} PLN</p>
          </li>
        </ul>

        <div className={styles.buttons}>
          <button onClick={registerOrder} className={styles.confirmOrder}>
            Przechodzę do płatności
          </button>
          <button onClick={close} className={styles.declineOrder}>
            Anuluj
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirm;
