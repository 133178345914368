import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import { Routes, Route, useParams, useSearchParams } from "react-router-dom";
import CustomerPage from "../../components/layout/CustomerPage";
import CustomerGalleryList from "../../components/customer-panel/customer-gallery-list/CustomerGalleryList";
import Alert from "../../components/layout/Alert/Alert";

const GalleriesWrapper = styled.section``;

const CustomerPanelPage = ({ route, navigate }) => {
  const [galleries, setGalleries] = useState([]);
  const [message, setMessage] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setMessage(searchParams.get("message"));

    const getCustomerGalleries = async () => {
      await axios.get("/galleries/customer").then((res) => {
        const sentGalleries = res.data.filter((gallery) => gallery.status !== "new");

        setGalleries(sentGalleries);
      });
    };
    getCustomerGalleries();
  }, []);

  return (
    <CustomerPage>
      <GalleriesWrapper>
        <h3>Lista Twoich galerii</h3>
        <p>Tutaj znajdziesz listę udostępnionych przez fotografa galerii</p>
        <CustomerGalleryList galleries={galleries} />
      </GalleriesWrapper>
      {message ? <Alert message={message} close={() => setMessage("")} /> : ""}
    </CustomerPage>
  );
};

export default CustomerPanelPage;
